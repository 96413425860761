import React from "react"
import { PageProps } from "gatsby"
import { graphql } from "gatsby"
import { useIntl } from "react-intl"

import { Seo } from "../components/Seo/Seo"

import { Navbar } from "../components/Navbar/Navbar"
import { Breadcrumb } from "../components/Breadcrumb/Breadcrumb"
import { SelectLanguageModal } from "../components/SelectLanguageModal/SelectLanguageModal"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { LayoutMdx } from "../components/LayoutMdx/LayoutMdx"
import { Drawer } from "../components/Drawer/Drawer"
import {
  getLessonUrl,
  navigateToHome,
  navigateToLessons,
} from "../i18n/utils/getUrl"
import { Separator } from "../components/LayoutMdx/components"
import { FormattedMessage } from "../components/FormattedMessage/FormattedMessage"
import { LessonsPicker } from "../components/LessonsPicker/LessonsPicker"
import { GlobalWrapper } from "../components/GlobalWrapper/GlobalWrapper"
import { Footer } from "../components/Footer/Footer"
import { MobileNavbar } from "../components/MobileNavbar/MobileNavbar"

type Props = PageProps<
  {
    mdx: {
      id: string
      body: string
      frontmatter: {
        order: number
        created_at: string
        lang: string
        level: string
        seo_description: string
        seo_title: string
        title: string
        updated_at: string
        breadcrumb_title: string
      }
    }

    lessons: {
      nodes: {
        frontmatter: {
          id: string
          level: string
          lang: string
          order: number
          url: string
          title: string
          description: string
        }
      }[]
    }
  },
  {
    langKey: string
    level: string
    canonicals: Array<{
      lang: string
      url: string
      isDefault: boolean
      label: string
    }>
  }
>

const LessonPage: React.FC<Props> = (props) => {
  const intl = useIntl()

  const { langKey } = props.pageContext
  const actualOrder = props.data.mdx.frontmatter.order
  const nextLesson = props.data.lessons.nodes
    .filter(({ frontmatter }) => frontmatter.order > actualOrder)
    .sort((a, b) => a.frontmatter.order - b.frontmatter.order)[0]

  const breadcrumb = [
    {
      url: navigateToHome(langKey),
      label: intl.formatMessage({ id: "lessons.breadcrumb.home" }),
    },
    {
      url: navigateToLessons(langKey),
      label: intl.formatMessage({ id: "lessons.breadcrumb.label" }),
    },
    { label: props.data.mdx.frontmatter.breadcrumb_title },
  ]

  return (
    <GlobalWrapper>
      <Seo
        title={props.data.mdx.frontmatter.seo_title}
        description={props.data.mdx.frontmatter.seo_description}
        lang={props.pageContext.langKey}
        langUrls={props.pageContext.canonicals}
      />

      <SelectLanguageModal languages={props.pageContext.canonicals} />

      <div className="">
        <div className="relative overflow-hidden ">
          <Navbar langKey={props.pageContext.langKey} />
        </div>

        <div className="py-4">
          <div className="px-4">
            <div className="max-w-3xl mx-auto">
              <Breadcrumb items={breadcrumb} />
            </div>
            <h1 className="max-w-3xl font-display mx-auto mt-2 text-4xl font-bold text-slate-900  ">
              {props.data.mdx.frontmatter.title}
            </h1>
          </div>

          <div>
            {props.data.mdx && (
              <>
                <LayoutMdx>
                  <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
                </LayoutMdx>
              </>
            )}
          </div>
        </div>

        {nextLesson && (
          <div className="max-w-3xl pb-10 mx-auto">
            <Separator />
            <h3 className="mt-12 text-3xl font-bold font-display">
              <FormattedMessage id="lessons.go-to-next-lesson" />
            </h3>
            <div className="mt-8">
              <LessonsPicker
                lessons={[
                  {
                    id: "fake-id",
                    url: getLessonUrl({
                      lang: nextLesson.frontmatter.lang,
                      level: nextLesson.frontmatter.level,
                      url: nextLesson.frontmatter.id,
                    }),
                    title: nextLesson.frontmatter.title,
                    description: nextLesson.frontmatter.description,
                    finished: false,
                  },
                ]}
              />
            </div>
          </div>
        )}

        <Footer lang={props.pageContext.langKey} />
        <Drawer langKey={props.pageContext.langKey} />
      </div>
      <MobileNavbar langKey={props.pageContext.langKey} />
    </GlobalWrapper>
  )
}

export default LessonPage

export const query = graphql`
  query LessonPageQuery($level: String, $langKey: String, $id: String) {
    mdx(
      frontmatter: {
        lang: { eq: $langKey }
        level: { eq: $level }
        category: { eq: "lesson" }
        id: { eq: $id }
      }
    ) {
      body
      frontmatter {
        created_at
        lang
        level
        order
        category
        title
        breadcrumb_title
        seo_description
        seo_title
        title
        updated_at
      }
    }

    lessons: allMdx(
      filter: {
        frontmatter: { lang: { eq: $langKey }, category: { eq: "lesson" } }
      }
      sort: { fields: frontmatter___order, order: ASC }
    ) {
      nodes {
        frontmatter {
          id
          level
          lang
          order
          url
          title
          description
        }
      }
    }
  }
`
